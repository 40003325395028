:root {
    --primary-color: #6B46C1;
    --secondary-color: #4A5568;
    --accent-color: #38B2AC;
    --background-color: #FFFFFF;
    --text-color: #2D3748;
    --light-gray: #EDF2F7;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
}

.header {
    position: fixed;
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(5px);
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
}

.nav-links {
    display: flex;
    gap: 2rem;
    list-style: none;
}

.nav-links a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: var(--primary-color);
}

.nav-links .cta-button {
    background: #9380d4;
    color: white !important;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
}

.nav-links .cta-button:hover {
    background: #8B6FE0;
}

.hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, #8B6FE0 0%, #38B2AC 100%);
    background-image: url('../images/floating-shapes.svg'), url('../images/hero-pattern.svg'), linear-gradient(135deg, #8B6FE0 0%, #38B2AC 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover, cover, auto;
    position: relative;
    color: white;
    padding: 2rem;
    overflow: hidden;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 50%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 100%);
    pointer-events: none;
}

.hero-content {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.hero h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.hero p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.cta-button-large {
    display: inline-block;
    background: white;
    color: var(--primary-color);
    padding: 1rem 2rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.1rem;
    transition: transform 0.3s ease;
}

.cta-button-large:hover {
    transform: translateY(-2px);
}

.tech-stack, .services {
    padding: 5rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.benefits-grid, .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 3rem;
}

.benefit-card, .service-card {
    padding: 2rem;
    background: var(--light-gray);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.benefit-card:hover, .service-card:hover {
    transform: translateY(-5px);
}

h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    color: var(--primary-color);
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.contact {
    background: var(--light-gray);
    padding: 5rem 2rem;
}

.contact-container {
    max-width: 800px;
    margin: 0 auto;
}

.contact-form {
    display: grid;
    gap: 1rem;
    margin-top: 2rem;
}

.contact-form input,
.contact-form textarea {
    padding: 1rem;
    border: 1px solid #CBD5E0;
    border-radius: 4px;
    font-size: 1rem;
}

.contact-form textarea {
    min-height: 150px;
}

.submit-button {
    background: var(--primary-color);
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background: #553C9A;
}

.footer {
    background: var(--secondary-color);
    color: white;
    padding: 3rem 2rem;
}

.footer-info h3 {
    color: #D7CCFF;  /* Much lighter, softer purple */
    margin-bottom: 0.5rem;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-links ul {
    list-style: none;
}

.footer-links a {
    color: white;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.footer-links a:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.5rem;
    }

    .nav-links {
        display: none;
    }

    .footer-content {
        flex-direction: column;
        gap: 2rem;
        text-align: center;
    }
}
